html {
  font-family: $global-font-family;
  font-weight: $global-font-weight;
  font-display: swap;
  font-size: $global-font-size;
  line-height: $global-line-height;
  width: 100%;
}

/* scrollbar, only support webkit */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;

  &:hover {
    background-color: $scrollbar-hover-color;
  }
}

::selection {
  background-color: $selection-color;

  [theme="dark"] & {
    background-color: $selection-color-dark;
  }
}

body {
  background-color: $global-background-color;
  color: $global-font-color;
  @include overflow-wrap(break-word);
  scrollbar-color: auto;

  &[theme="dark"] {
    color: $global-font-color-dark;
    background-color: $global-background-color-dark;
  }
}

@include ms;
@include link(true, true);

@import "../_partial/mask";
@import "../_partial/icon";
@import "../_partial/details";
@import "../_partial/fixed-button";
@import "../_partial/cookieconsent";

img {
  @include object-fit(contain);
}
