footer {
  height: 2rem;
  width: 100%;
  text-align: center;
  line-height: 1.25rem;
  padding: 1rem 0;

  .footer-container {
    font-size: 0.875rem;

    .footer-line {
      width: 100%;

      .icp-br {
        display: none;
      }
    }
  }

  @include blur;
}
